function NameAnim() {

    return (
        <svg id="hi-there" viewBox="0 0 334.9 45.25">
            <path className="cls-1"
                d="M97.87,58.93V29.43a2.71,2.71,0,0,1,2.75-2.75,2.75,2.75,0,0,1,2.8,2.75v12h13v-12a2.71,2.71,0,0,1,2.75-2.75,2.75,2.75,0,0,1,2.8,2.75v29.5a2.75,2.75,0,0,1-2.8,2.75,2.71,2.71,0,0,1-2.75-2.75v-12h-13v12a2.75,2.75,0,0,1-2.8,2.75A2.71,2.71,0,0,1,97.87,58.93Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M138.22,30.43a2.7,2.7,0,0,1-2.7,2.75,2.73,2.73,0,0,1-2.7-2.75V29.38a2.69,2.69,0,0,1,2.7-2.7,2.66,2.66,0,0,1,2.7,2.7Zm0,9V59a2.66,2.66,0,0,1-2.7,2.7,2.69,2.69,0,0,1-2.7-2.7V39.48a2.67,2.67,0,0,1,2.7-2.75A2.64,2.64,0,0,1,138.22,39.48Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M146,66.18c2.3-.75,3.5-2.3,3.4-4.5a3.64,3.64,0,0,1-2.85-3.4v-.4a3.24,3.24,0,0,1,3.4-3.35c2.25,0,3.6,1.7,3.6,4.4v.55c0,3.4-.9,5.7-2.45,7.3a7.59,7.59,0,0,1-4.7,2.15,1.39,1.39,0,0,1-.4-2.75Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M174.22,29.43A2.71,2.71,0,0,1,177,26.68a2.78,2.78,0,0,1,2.8,2.75v29.5a2.78,2.78,0,0,1-2.8,2.75,2.71,2.71,0,0,1-2.75-2.75Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M188,38a4.25,4.25,0,0,0,3.4-4.5,3.54,3.54,0,0,1-2.85-3.35v-.4a3.24,3.24,0,0,1,3.4-3.35c2.2,0,3.6,1.7,3.6,4.4v.5c0,3.4-.9,5.75-2.45,7.3a7.33,7.33,0,0,1-4.7,2.15,1.36,1.36,0,0,1-1.55-1.4A1.43,1.43,0,0,1,188,38Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M221,47V59a2.67,2.67,0,0,1-2.65,2.65,2.74,2.74,0,0,1-2.7-2.65V47a5.1,5.1,0,0,0-10.2.15V59c0,.1,0,.25,0,.35v.15c-.05.1-.05.2-.15.3v.25a2.92,2.92,0,0,1-2.5,1.6c-.2,0-.35,0-.5,0a2.72,2.72,0,0,1-2.15-2.6V39.38a2.65,2.65,0,0,1,2.65-2.7,2.54,2.54,0,0,1,2.4,1.5,10.52,10.52,0,0,1,5.35-1.5,10.38,10.38,0,0,1,7.85,3.45,10.3,10.3,0,0,1,7.85-3.45,10.45,10.45,0,0,1,10.4,10.5V59a2.67,2.67,0,0,1-2.65,2.65,2.71,2.71,0,0,1-2.7-2.65V47.18A5.12,5.12,0,0,0,226.17,42,5.19,5.19,0,0,0,221,47Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M261.61,43.68a18.36,18.36,0,0,1,18.36-18,18.7,18.7,0,0,1,11.55,3.9,2.87,2.87,0,0,1-3.5,4.55,12.46,12.46,0,0,0-8-2.75,13.26,13.26,0,0,0-9,3.6,12.33,12.33,0,0,0-3.65,8.7,12.16,12.16,0,0,0,3.65,8.7,13.26,13.26,0,0,0,9,3.6,12.56,12.56,0,0,0,8-2.75,3,3,0,0,1,4,.55,3,3,0,0,1-.5,4A18.7,18.7,0,0,1,280,61.68,18.29,18.29,0,0,1,261.61,43.68Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M322.32,49.23V59a2.67,2.67,0,0,1-2.65,2.65,2.74,2.74,0,0,1-2.61-2.15,11.31,11.31,0,0,1-6.65,2.15,11.61,11.61,0,0,1-8.5-3.65,12.8,12.8,0,0,1-3.4-8.8,12.62,12.62,0,0,1,3.4-8.8,11.51,11.51,0,0,1,8.5-3.75,11.35,11.35,0,0,1,6.65,2.2,2.74,2.74,0,0,1,2.61-2.2,2.68,2.68,0,0,1,2.65,2.7Zm-5.31,0a7.35,7.35,0,0,0-1.95-5.15,6.17,6.17,0,0,0-4.65-2,6,6,0,0,0-4.65,2,7.32,7.32,0,0,0-1.9,5.15,7.5,7.5,0,0,0,1.9,5.15,6.3,6.3,0,0,0,4.65,1.95,6.45,6.45,0,0,0,4.65-1.95A7.53,7.53,0,0,0,317,49.23Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M347.51,39.33A2.67,2.67,0,0,1,344.86,42a4.78,4.78,0,0,0-3,1,7.54,7.54,0,0,0-2.3,2.4,15.34,15.34,0,0,0-2.05,4.2V59a2.72,2.72,0,0,1-2.75,2.65A2.67,2.67,0,0,1,332.11,59V39.33a2.62,2.62,0,0,1,2.65-2.6,2.68,2.68,0,0,1,2.75,2.6v.35a6.57,6.57,0,0,1,1.05-1,11,11,0,0,1,6.3-2A2.59,2.59,0,0,1,347.51,39.33Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M352.86,54.53a2.6,2.6,0,0,1,3.75-.4,9.18,9.18,0,0,0,5.5,2.2,6.67,6.67,0,0,0,3.4-.95,2,2,0,0,0,.9-1.5.75.75,0,0,0-.15-.5c-.05-.15-.25-.35-.65-.6a11.59,11.59,0,0,0-4-1.4h-.05a19.19,19.19,0,0,1-4.35-1.3,8.36,8.36,0,0,1-3.45-2.9,6.06,6.06,0,0,1-.85-3.15,7,7,0,0,1,2.95-5.5A10.76,10.76,0,0,1,362,36.68a12.86,12.86,0,0,1,7.5,2.75,2.76,2.76,0,0,1,.8,3.7,2.65,2.65,0,0,1-3.7.7c-1.6-1-3-1.85-4.6-1.85a5.09,5.09,0,0,0-3,.9,1.52,1.52,0,0,0-.8,1.15.54.54,0,0,0,.1.4,1.35,1.35,0,0,0,.55.5,10.19,10.19,0,0,0,3.65,1.2l.05,0h.05a18,18,0,0,1,4.65,1.45,7.77,7.77,0,0,1,3.55,2.95,6.48,6.48,0,0,1,.95,3.3,7.21,7.21,0,0,1-3.15,5.8,11.38,11.38,0,0,1-6.5,2,14.52,14.52,0,0,1-8.9-3.45A2.67,2.67,0,0,1,352.86,54.53Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M381,58a12.48,12.48,0,0,1-3.45-8.8A12.9,12.9,0,0,1,381,40.48a11.68,11.68,0,0,1,8.6-3.8,11.06,11.06,0,0,1,8.35,3.8,12.49,12.49,0,0,1,3.5,8.75,12.1,12.1,0,0,1-3.5,8.8,11.06,11.06,0,0,1-8.35,3.8A11.68,11.68,0,0,1,381,58Zm2-8.8a8.09,8.09,0,0,0,1.8,5.3,6.77,6.77,0,0,0,4.8,1.85,6.55,6.55,0,0,0,4.6-1.85,7.68,7.68,0,0,0,1.95-5.3,7.52,7.52,0,0,0-1.95-5.2,7.06,7.06,0,0,0-4.6-1.9,7.27,7.27,0,0,0-4.8,1.9A7.91,7.91,0,0,0,383,49.23Z"
                transform="translate(-96.87 -24.68)" />
            <path className="cls-1"
                d="M430.76,47.08V59a2.62,2.62,0,0,1-2.6,2.65A2.7,2.7,0,0,1,425.51,59v-12a5.13,5.13,0,0,0-5.2-5,5,5,0,0,0-5,5V59a1,1,0,0,1-.1.5,2.73,2.73,0,0,1-2.65,2.15A2.64,2.64,0,0,1,409.91,59V39.33a2.64,2.64,0,0,1,2.65-2.65A2.7,2.7,0,0,1,415,38.23a10,10,0,0,1,5.3-1.55A10.47,10.47,0,0,1,430.76,47.08Z"
                transform="translate(-96.87 -24.68)" />
        </svg>
    )
}
export default NameAnim;